import { defineComponent, onMounted, reactive, ref, getCurrentInstance, ComponentInternalInstance } from 'vue'
import { listByJobAndStatusAndLikeName, delayReward } from '@/api/index'
import { useRoute, useRouter } from 'vue-router'
import { ages, getStates, delay, timesStr } from '@/utils/index'
import { SET_RESUME } from '@/utils/constant'
import { Dialog } from 'vant'
import './index.less'


export default defineComponent({
    name: 'Resume',
    setup () {

        onMounted(() => {
            _listByJobAndStatusAndLikeName()
            onCheckData()
        })

        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const route = useRoute()
        const job = reactive({
            data: [],
            pageNum: 0,
            pageSize: 10,
            totalPage: 1,
            totalRecord: 0,
            next: true,
            noData: false
        })
        const loading = ref(false)
        const finished = ref(false)

        const onCheckData = () => {
            delay(() => {
              job.data.length > 0 ?  job.noData = false : job.noData = true
            },500)
        }


        const onLoad = () => {
            // 数据全部结束
            if (job.data.length >= job.totalRecord) {
                finished.value = true
                //加载状态结束
                loading.value = false 
                console.log('进来了啊。')
            }
           
            let timer: any = setTimeout(() => {
                clearTimeout(timer)
                timer = null
                
                //加载状态结束
                loading.value = true 
                // 数据全部结束
                if (job.data.length >= job.totalRecord && job.data.length != 0) {
                    loading.value = false 
                    finished.value = true
                }

                if (!job.next)return
                const start = (job.pageNum + 1) * job.pageSize - job.pageSize
                _listByJobAndStatusAndLikeName(10, start)
            },1000)
        }


        const _listByJobAndStatusAndLikeName = async (limit = 10, start = 0) => {
            
            
            if (job.data.length >= job.totalRecord && job.data.length !== 0) {
                //加载状态结束
                loading.value = false 
                finished.value = true
                return 
            }
            
            job.next = false

            const { id, state } = route.query

            const res: any = await listByJobAndStatusAndLikeName({
                data:{ jobId: id,"name":"","status": state},
                limit: limit, 
                start: start
            })
            job.data = job.data.concat(res.data);
            console.log(job.data.length, '里面看看。。')
            job.pageNum = res.pageNum 
            job.pageSize = res.pageSize
            job.totalPage = res.totalPage
            job.totalRecord = res.totalRecord
            job.next = true
            job.data.length > 0 ? job.noData = false : job.noData = true
            console.log(res, '招聘职位.')
        }

        const shortName = (name: string) =>{
            return name.length > 8 ? name.substring(0, 8) +'...' : name;
        }

        const onInfo = (item: any) => {
            localStorage.setItem(SET_RESUME, JSON.stringify(item))
            const { id, state } = route.query
            router.push({path: `/resumeInfo`, query: { jobId: id, state, id: item.id } })
        }

        const getAges = (str: string) => {
            return ages(str)
        }

        const onGetStates = (key: number) => {
            return getStates(key)
        }

         //默认组件
         const defaultUI = () => {
            return (
                <div class="default-container">
                    <img class="default-pic" src="http://img.tueneitui.com/default2.png" />
                    <div class="publish-slogan">无简历</div>
                </div>
            )
        }

        const outUI = (item: any) => {
             if (!item.eliminateTime)return
            return (
                <div class="normal-fail-box">
                    <div class="item"><span class="label-font">淘汰时间:</span> {item.eliminateTime}</div>
                    <div class="item"><span class="label-font">淘汰阶段:</span> { onGetStates(Number(item.eliminateStatus))}</div>
                    <div><span class="label-font">淘汰原因:</span> {item.eliminateReason}</div>
                </div>
            )
        }

        const _delayReward  = async(id:any) =>{
            const res:any = await delayReward(id)
            if (res.code !== 0) {
                return proxy.$toast(`${res.message}`)
            } else {
                proxy.$toast('操作' + res.message)
                let timeout:any = setTimeout(() => {
                    clearTimeout(timeout)
                    timeout = null
                    _listByJobAndStatusAndLikeName()
                    onCheckData()
                }, 2000)
            }
        } 

       const onDelays = (day:any, obj:any) => {
        if (day > 2) { //（1）如果剩余天数 > 2 天，则toast提示：“距离结束时间2天才可申请”
            return proxy.$toast(`距离结束时间2天才可申请`)
        } 
        if (obj.expirationSent) {
            return proxy.$toast(`已经申请过延长结算`)
        }
        if (day <= 2) { //（2）如果剩余天数 <= 2 天，则二次确认弹窗：“每笔奖金只能申请延长一次7天，确认吗？”

            Dialog.confirm({
                message: '每笔奖金只能申请延长一次7天，确认吗？',
                closeOnClickOverlay: true,
            }).then(() => {
                //_recover()
                _delayReward(obj.id)
            }).catch(() => {
                console.log('')
            });
        } 
    } 

        const delays = (item:any) => {
            const { state } = route.query
            if (Number(state) !== 0) {
                return (
                    <div>
                        {
                            timesStr(item.expirationTime).day < 0 ? <div class='delayboxs'>奖金已结算</div> : (
                                <div class='delayboxs'>
                                    还剩
                                    <span style={{color: '#FE4A49',fontWeight: 'bold'}}>{timesStr(item.expirationTime).str}</span>
                                    自动结算
                                    <span class='btnss' onClick={()=> onDelays(timesStr(item.expirationTime).day,item)}>申请延长时间</span>
                                </div>
                            )
                        }
                    </div>
                )
            }
            return ''
        }

        const resumeListUI = () => {
            return (
                <van-list 
                        v-model={ loading.value } 
                        finished={ finished.value }
                        finished-text={ job.data.length === 0 ? '' : '' }
                        onLoad={ onLoad }
                        >

                            <ul class="resume-list-box">
                                {
                                    job.data.map((item: any) => {
                                        return (
                                            <li class="resume-item" onClick={ ()=> onInfo(item) }>
                                                <div class="flex-box justify-content-between align-items-center">
                                                   
                                                    <div class="flex-box justify-content-between">
                                                        <div class="avatar-box">
                                                            <img src={item.candidateInfo.imgUrl} />
                                                        </div>
                                                        <div>
                                                            <div class="frist-bar-box">
                                                                <span class="name-title">{ shortName(item.candidateInfo.name) }</span>
                                                                <label>{getAges(item.candidateInfo.birth)}岁</label>｜<label>{ item.candidateInfo.gender === 1 ? '男' : '女' }</label>|
                                                                <label>{item.candidateInfo.startWork}年工作经验</label>｜
                                                                <label>{item.candidateInfo.education === '不限'? '学历不限' : item.candidateInfo.education}</label>
                                                            </div>
                                                            <div class="sencod-bar-box">
                                                                联系方式:
                                                                {
                                                                    !item.payStatus ? 
                                                                    (
                                                                        <span class='mark-box'><img src='https://img.fanwoon.com/jiesuo%402x.png' alt=''/><span>邀约面试解锁联系方式</span></span>
                                                                    ) : item.candidateInfo.phone
                                                                }
            
                                                            </div>
                                                            {
                                                                item.candidateInfo.businesses.length === 0 ? '' : (
                                                                    <div class="last-bar-box">{item.candidateInfo.businesses[0].company} - {item.candidateInfo.businesses[0].jobTitle}</div>
                                                                )
                                                            }
                                                           
                                                        </div>
                                                    </div>
                                                    <div><van-icon name="arrow" /></div>
                                                </div>
                                                
                                                { outUI(item) }
                                                { delays(item) }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                    </van-list>
            )
        }

        return () => (
            <div class="resume-page">
                <div class="header-box flex-box justify-content-between">
                    <div>简历列表</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="container-box">
                    {
                        job.data.length === 0 && job.noData ? defaultUI()  : ''
                    }
                    {
                        job.data.length > 0 ? resumeListUI() : ''
                    } 
                </div>
               
            </div>
        )
    }
})